import styled from "styled-components";
import { breakpointsV2 } from "../design-system/Breakpoints";

const HeroRow = styled.div`
    display: flex;
    overflow-y: visible;

    // Smallest screens
    @media (max-width: ${breakpointsV2.small}) {
        flex-wrap: wrap;
        flex-direction: column;
    }

    @media (min-width: ${breakpointsV2.small}) {
        padding-top: 100px;
        flex-direction: row;
    }
`;

export default HeroRow;
