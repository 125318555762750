import React from "react";
import * as colors from "../styles/colors";

import { LightLogo } from "../assets/logos";

// Composite themes, primarily used by the Header, but may be useful
// throughout the app as well.

export const DARK = {
    logo: (props) => <LightLogo {...props} />,
    topNavLink: {
        activeColor: "#fff",
        color: colors.textColors[4],
        activeColorMobile: colors.BLACK,
        colorMobile: colors.textColors[3],
    },
    callToAction: {
        background: colors.LIGHT_TEAL,
        color: colors.DARK_GREEN,
    },
    learnMore: {
        background: "#fff",
        color: colors.DARK_GREEN,
        border: colors.DARK_GREEN,
    },
    tryItOut: {
        background: colors.LIGHT_TEAL,
        color: colors.DARK_GREEN,
    },
    joinWaitlist: {
        background: colors.LIGHT_TEAL,
        color: colors.DARK_GREEN,
    },
};

export const LIGHT = {
    logo: (props) => <LightLogo {...props} />,
    topNavLink: {
        activeColor: colors.BLACK,
        color: colors.BLACK,
        activeColorMobile: colors.BLACK,
        colorMobile: colors.textColors[3],
    },
    callToAction: {
        background: colors.LIGHT_TEAL,
        color: colors.DARK_GREEN,
    },
    learnMore: {
        background: "#fff",
        color: colors.DARK_GREEN,
        border: colors.DARK_GREEN,
    },
    tryItOut: {
        background: colors.TEAL,
        color: colors.WHITE,
    },
    joinWaitlist: {
        background: colors.LIGHT_TEAL,
        color: colors.DARK_GREEN,
    },
};
