import React from "react";
import styled from "styled-components";

const PointerWrapper = styled.div`
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
`;

export default function Wrapper({ children, ...props }) {
    return <PointerWrapper {...props}>{children}</PointerWrapper>;
}
