// Base colors used by themes

export const DARK_GREEN = "#053233";

export const TEAL = "#2C7873";
export const LIGHT_TEAL = "#77D3B9";
export const LIGHTER_TEAL = "#ECF5F7";

export const DISABLED = "#A4A0A9";

export const textColors = [
    "#161A1A",
    "#4F5C5C",
    "#879999",
    "#AEC1C1",
    "#CED9D9",
    "#E5ECEC",
    "#F3F5F5",
];
export const WHITE = textColors[6];
export const BLACK = textColors[0];
export const GRAY = textColors[1];

export const BEIGE = "#FDF3EA";
export const NEW_TEAL = "#24695C";

export const SUBTLE_RED = "#FFDCDB";
export const SUBTLE_BLUE = "#ECF5F7";
