import React from "react";
import styled from "styled-components";

const SparkleWrapper = styled.div`
    position: absolute;
    right: 41px;
    bottom: 14px;
`;

export default function Sparkle() {
    return (
        <SparkleWrapper>
            <svg
                width="194"
                height="168"
                viewBox="0 0 194 168"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.250488 10.8189V32.8295C0.250488 34.4196 1.19226 35.8587 2.64936 36.4951L18.2437 43.3067C20.3605 44.5642 23.3594 43.8307 24.5942 43.3067L39.296 36.2635C40.3372 35.7647 40.9998 34.7126 40.9998 33.558V10.1198C40.9998 8.9501 40.3199 7.88706 39.2581 7.39645L25.1234 0.865688C22.5832 -0.391825 19.3021 -0.00756807 18.2437 0.341741L2.64936 7.15335C1.19226 7.78982 0.250488 9.22888 0.250488 10.8189Z"
                    fill="#5F5F5F"
                />
                <path
                    d="M70.3638 144.582V159.162C70.3638 160.752 71.3055 162.191 72.7626 162.828L83.4497 167.496C84.9893 168.41 87.1703 167.877 88.0683 167.496L98.2958 162.596C99.3371 162.097 99.9996 161.045 99.9996 159.891V143.883C99.9996 142.713 99.3198 141.65 98.2579 141.16L88.4532 136.63C86.6058 135.715 84.2195 135.994 83.4497 136.249L72.7626 140.917C71.3055 141.553 70.3638 142.992 70.3638 144.582Z"
                    fill="#5F5F5F"
                />
                <path
                    d="M140.283 91.4281V122.107C140.283 123.697 141.225 125.136 142.682 125.773L164.001 135.085C166.791 136.742 170.744 135.775 172.372 135.085L192.293 125.541C193.334 125.042 193.997 123.99 193.997 122.836V90.729C193.997 89.5593 193.317 88.4963 192.255 88.0057L173.069 79.1411C169.721 77.4835 165.396 77.99 164.001 78.4505L142.682 87.7626C141.225 88.399 140.283 89.8381 140.283 91.4281Z"
                    fill="#5F5F5F"
                />
            </svg>
        </SparkleWrapper>
    );
}
