import React, { useState } from "react";
import styled from "styled-components";
import Block from "./Block";

import * as colors from "../styles/colors";
import Collapse from "./faq/Collapse";
import Expand from "./faq/Expand";
import breakpoints from "./design-system/Breakpoints";
import { fontFamilies } from "../styles/fonts";

const QUESTIONS = [
    {
        question: "TestBox is free (awesome!), so how do you make money?",
        answer:
            "We are paid by vendors when you buy a license after using TestBox. We get paid roughly the same no matter which vendor you choose, so we have no incentive to push you towards a specific vendor. Any recommendations we provide are entirely based on your requirements.",
    },
    {
        question: "What information do you collect, why, and how is it used?",
        answer: (
            <>
                <p>
                    We collect the standard information used in a sales process
                    to best recommend the right solution to you (e.g., your use
                    cases, size of company, etc.).
                </p>
                <p>
                    We also collect your name and email so we can help you get
                    up and running with TestBox and register your interest with
                    our vendor partners. (They won’t reach out to you unless you
                    explicitly ask to talk to them!)
                </p>
                <p>
                    We will also aggregate your feedback on which software you
                    do and don't like to help our vendor partners learn how
                    their products can be improved. None of that data will be
                    attributed to you specifically.
                </p>
            </>
        ),
    },
    {
        question: "How are your recommendations built?",
        answer:
            "We use the information you share during onboarding around most important use cases, your industry, size of company, budget, etc. to match you to customers with similar profiles. Then, we share the software solutions that have made them most successful. We are essentially crowdsourcing the best answer for you based on your needs.",
    },
    {
        question: "How do you build an environment unique to our needs?",
        answer:
            "We use the information you provide during onboarding to create the environment for each of your chosen software solutions. For example: if you’re an eCommerce retailer, you'll see different data from a Manufacturing company. If you care most about a specific use case we’ll make sure that’s the first thing you see in TestBox and guide you through it.",
    },
    {
        question: "How do you determine which use cases to build for me?",
        answer:
            "The use cases are ever evolving, however the ones you see today are from frequent customer co-design feedback sessions, our own internal software expertise, and your feedback as you use the product. The more people use TestBox, the smarter we get.",
    },
];

const QAWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.small}) {
        flex-basis: calc(50% - 114px);
        max-width: calc(50% - 114px);

        :nth-of-type(2n + 1) {
            padding-right: 57px;
        }
        :nth-of-type(2n) {
            padding-left: 57px;
        }
    }

    padding-bottom: 40px;
`;

const QuestionWrapper = styled.div`
    font-family: ${fontFamilies.header};
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 20.48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    line-height: 136%;
`;

const Question = styled.span`
    flex: 1;

    @media (max-width: ${breakpoints.small}) {
        padding-right: 20px;
    }
`;

const AnswerWrapper = styled.div`
    font-family: Roboto, Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 132%;
    padding-right: 54px;
    padding-top: 18px;
`;

function QA({ question, answer }) {
    const [expanded, setExpanded] = useState(false);
    return (
        <QAWrapper>
            <QuestionWrapper onClick={() => setExpanded(!expanded)}>
                <Question>{question}</Question>
                {expanded ? <Collapse /> : <Expand />}
            </QuestionWrapper>
            {expanded ? <AnswerWrapper>{answer}</AnswerWrapper> : null}
        </QAWrapper>
    );
}

const QuestionsWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: ${breakpoints.small}) {
        flex-direction: column;
    }
`;

const StyledBlock = styled(Block)`
    & h2 {
        color: ${colors.NEW_TEAL};
    }
`;

export default function FAQ() {
    return (
        <StyledBlock
            header="Everything you'd want to know!"
            backgroundColor={colors.BEIGE}
            id="faq"
        >
            <QuestionsWrapper>
                {QUESTIONS.map((qa, idx) => (
                    <QA key={`qa${idx}`} {...qa} />
                ))}
            </QuestionsWrapper>
        </StyledBlock>
    );
}
