import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import * as colors from "../styles/colors";
import Sparkle from "./footer/Sparkle";
import { DarkLogo } from "../assets/logos";
import FixedWidth from "./design-system/FixedWidth";
import breakpoints from "./design-system/Breakpoints";

const Background = styled.div`
    background-color: ${colors.BLACK};
`;

const Container = styled.div`
    display: flex;
    position: relative;

    @media (min-width: ${breakpoints.large}) {
        padding: 120px 0 120px 0;
    }

    @media (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.large}) {
        padding: 120px 120px;
        flex-direction: row;
    }

    @media (max-width: ${breakpoints.small}) {
        flex-wrap: wrap;
        padding: 40px;
        flex-direction: column;
    }
`;

const Column = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.small}) {
        :not(:first-of-type) {
            padding-top: 20px;
        }
    }
`;

const LogoColumn = styled(Column)`
    flex: 2;
`;

const A = styled(Link)`
    color: ${colors.WHITE};
    text-decoration: none;
    margin-bottom: 24px;
    font-family: Roboto, Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 124%;
    z-index: 5;
`;

const MailA = styled.a`
    color: ${colors.WHITE};
    text-decoration: none;
    margin-bottom: 24px;
    font-family: Roboto, Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 124%;
`;

const H4 = styled.h4`
    color: ${colors.LIGHT_TEAL};
    font-family: Roboto, Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 124%;
    text-transform: uppercase;
    margin: 0 0 36px;
    padding: 0;
`;

const Text = styled.div`
    color: ${colors.WHITE};
    font-family: Roboto, Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 124%;
`;

export default function Footer() {
    return (
        <Background>
            <FixedWidth>
                <Container>
                    <LogoColumn>
                        <div style={{ marginBottom: 32 }}>
                            <DarkLogo height={28} width={132} />
                        </div>
                        <Text>
                            Test and compare software before you buy
                        </Text>
                    </LogoColumn>
                    <Column></Column>
                    <Column>
                        <H4>Navigation</H4>
                        <A to="/">Product</A>
                        <A to="/about/">About</A>
                    </Column>
                    <Column>
                        <H4>About Us</H4>
                        <A to="/about/#mission">Our Mission</A>
                        <A to="/about/#team">Our Team</A>
                        <A to="/about/#investors">Investors / Advisors</A>
                    </Column>
                    <Column>
                        <H4>Contact</H4>
                        <MailA href="mailto:heretohelp@testbox.com">
                            heretohelp@testbox.com
                        </MailA>
                        <Sparkle />
                    </Column>
                </Container>
            </FixedWidth>
        </Background>
    );
}
