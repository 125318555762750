import React from "react";
import styled, { css } from "styled-components";
import * as colors from "../styles/colors";
import * as fonts from "../styles/fonts";
import FixedWidth from "./design-system/FixedWidth";
import breakpoints from "./design-system/Breakpoints";

const Container = styled.div`
    background-color: ${({ backgroundColor }) => backgroundColor};
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.large}) {
        padding: 120px 0 120px 0;
    }
    @media (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.large}) {
        padding: 120px;
    }
    @media (max-width: ${breakpoints.small}) {
        padding: 30px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 80px;
`;

export const Body = styled.div`
    display: flex;
    font-family: ${fonts.fontFamilies.text};
`;

export const H2 = styled.h2`
    font-family: ${fonts.HEADER};
    color: ${colors.BLACK};
    font-weight: 800;
    margin: 0;
    flex: 1;

    @media (min-width: ${breakpoints.small}) {
        font-size: ${fonts.HEADER_SIZES.desktop};
        line-height: 136%;
    }
    @media (max-width: ${breakpoints.small}) {
        font-size: ${fonts.HEADER_SIZES.mobile};
        line-height: ${fonts.lineSizes.mobile};
    }
`;

const sharedButton = ({ theme, buttonColor }) => {
    return css`
        background-color: ${theme[buttonColor].background};
        color: ${theme[buttonColor].color};
        font-family: Roboto, Arial, sans-serif;
        border-radius: 4px;
        cursor: pointer;
        height: fit-content;
        text-align: center;
        ${theme[buttonColor].border
            ? "border: 1px solid " + theme[buttonColor].border + ";"
            : ""}

        @media (min-width: ${breakpoints.small}) {
            padding: 16px 26px 16px 26px;
        }
        @media (max-width: ${breakpoints.small}) {
            padding: 8px 12px 8px 12px;
        }
    `;
};

const Button = styled.div`
    ${sharedButton};
`;

const ButtonLink = styled.a`
    ${sharedButton};
    text-decoration: none;
`;

export default function Block({
    backgroundColor,
    buttonText,
    buttonClick,
    buttonLink,
    buttonColor,
    header,
    children,
    actions,
    ...props
}) {
    return (
        <Container backgroundColor={backgroundColor} {...props}>
            <FixedWidth>
                <Header>
                    <H2>{header}</H2>
                    {buttonText ? (
                        buttonLink ? (
                            <ButtonLink
                                href={buttonLink}
                                buttonColor={buttonColor}
                            >
                                {buttonText}
                            </ButtonLink>
                        ) : (
                            <Button
                                onClick={buttonClick}
                                buttonColor={buttonColor}
                            >
                                {buttonText}
                            </Button>
                        )
                    ) : actions ? (
                        actions
                    ) : null}
                </Header>
                <Body>{children}</Body>
            </FixedWidth>
        </Container>
    );
}

export const VerticalBlock = styled(Block)`
    && ${Body} {
        flex-direction: column;
    }
`;
