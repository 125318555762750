import React from "react";
import styled from "styled-components";
import * as colors from "../../styles/colors";

import Header from "../header";
import HeroRow from "../design-system/HeroRow";
import FixedWidth from "../design-system/FixedWidth";
import breakpoints from "../design-system/Breakpoints";
import { fontFamilies } from "../../styles/fonts";
import Illustration from "./Illustration";

export const HeroColumn = styled.div`
    flex: 1;
    overflow-y: visible;

    @media (min-width: ${breakpoints.large}) {
        padding-right: 120px;
    }
    @media (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.large}) {
        padding-left: 120px;
        padding-right: 100px;
    }
    @media (max-width: ${breakpoints.small}) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const H1 = styled.h1`
    font-family: ${fontFamilies.header};
    color: ${colors.NEW_TEAL};
    font-weight: 800;
    font-size: 3rem;
    line-height: 116%;
    margin-bottom: 24px;
`;

const Text = styled.div`
    font-family: Roboto, Arial, sans-serif;
    color: ${colors.textColors[1]};
    font-size: 1.125rem;
    line-height: 136%;
`;

// const WaitlistWrapper = styled.div`
//     @media (max-width: ${breakpoints.small}) {
//         padding-top: 40px;
//         padding-bottom: 40px;
//     }
//     @media (min-width: ${breakpoints.small}) {
//         padding-top: 80px;
//         padding-bottom: 80px;
//     }
// `;

// const Free = styled.span`
//     position: relative;
//     text-decoration: underline;
//     color: ${colors.LIGHT_TEAL};
//     font-weight: bold;

//     :hover {
//         cursor: pointer;
//     }
// `;

// const FreeTooltip = styled.div`
//     display: none;
//     position: absolute;
//     background-color: ${colors.textColors[0]};
//     padding: 16px;
//     left: 0px;
//     margin-top: 16px;
//     width: 340px;
//     color: white;

//     :hover,
//     ${Free}:hover && {
//         display: block;
//         z-index: 9000;
//     }
// `;

const Button = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 52px;
    background-color: ${colors.DARK_GREEN};
    color: white;
    border-radius: 4px;
    font-family: ${fontFamilies.text};
    margin: 40px 0 40px 0;
    text-decoration: none;
`;

export default function Hero() {
    return (
        <FixedWidth>
            <Header />
            <HeroRow>
                <HeroColumn>
                    <H1>Compare and test software in minutes</H1>
                    <Text>
                        Dive into a live sandbox with your competing options side-by-side, guided walkthroughs,
                        pre-configured use cases, and data that looks and feels like yours
                    </Text>
                    <Button href="https://onboarding.testbox.com">
                        Start testing now!
                    </Button>
                </HeroColumn>
                <Illustration />
            </HeroRow>
        </FixedWidth>
    );
}
