import React from "react";
import { useContext } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { ThemeContext } from "styled-components";
import * as colors from "../styles/colors";
import breakpoints, { breakpointsV2 } from "./design-system/Breakpoints";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (min-width: ${breakpoints.large}) {
        padding-top: 70px;
        padding-right: 140px;
    }
    @media (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.large}) {
        padding-left: 120px;
        padding-top: 70px;
        padding-right: 120px;
    }
    @media (max-width: ${breakpoints.small}) {
        padding-left: 10px;
        padding-top: 75px;
        padding-right: 10px;
    }
`;

const Logo = styled.div`
    height: 28px;
    background-color: transparent;
`;

const NavMenu = styled.div`
    @media (max-width: ${breakpoints.small}) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;

const MenuItem = styled(Link)`
    font-family: Roboto, Arial, sans-serif;
    text-decoration: none;

    &.active {
        position: relative;
        font-weight: 700;
    }

    color: ${(props) => props.theme.topNavLink.color};

    &.active {
        color: ${(props) => props.theme.topNavLink.activeColor};
    }

    @media (min-width: ${breakpointsV2.medium}) {
        margin-left: 60px;
    }
    @media (max-width: ${breakpointsV2.medium}) and (min-width: ${breakpointsV2.small}) {
        margin-left: 40px;
    }
    @media (max-width: ${breakpointsV2.small}) {
        margin-left: 10px;
        color: ${(props) => props.theme.topNavLink.colorMobile};

        &.active {
            color: ${(props) => props.theme.topNavLink.activeColorMobile};
        }
    }
`;

const CallToAction = styled(MenuItem)`
    background-color: ${(props) => props.theme.callToAction.background};
    color: ${(props) => props.theme.callToAction.color};

    @media (min-width: ${breakpoints.small}) {
        padding: 14px 16px;
        border-radius: 4px;
    }

    @media (max-width: ${breakpoints.small}) {
        padding: 7px 8px;
        border-radius: 2px;
    }

    @media (max-width: 450px) {
        display: none;
    }
`;

const PedalWrapper = styled.div`
    display: none;

    .active > && {
        display: block;
        position: absolute;
        left: calc(50% - 5px);

        @media (min-width: ${breakpoints.small}) {
            top: -45px;
        }
        @media (max-width: ${breakpoints.small}) {
            top: -30px;
        }
    }
`;

const Pedal = () => {
    return (
        <PedalWrapper>
            <svg
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.25 3.5154V8.38844C0.25 9.16808 0.703055 9.87666 1.41073 10.2038L4.88636 11.8106C5.43182 12.1536 6.20455 11.9535 6.52273 11.8106L9.65447 10.2227C10.3265 9.88193 10.75 9.19239 10.75 8.43889V3.48492C10.75 2.72102 10.3149 2.02384 9.62861 1.68825L6.65909 0.236091C6.00455 -0.106859 5.15909 -0.00206397 4.88636 0.0931998L1.41073 1.70002C0.703055 2.02718 0.25 2.73576 0.25 3.5154Z"
                    fill={colors.LIGHT_TEAL}
                />
            </svg>
        </PedalWrapper>
    );
};

export function Header() {
    const theme = useContext(ThemeContext);

    return (
        <Container>
            <Logo>{theme.logo({ height: 28 })}</Logo>
            <NavMenu>
                <MenuItem to="/" activeClassName="active">
                    <Pedal />
                    Product
                </MenuItem>
                <MenuItem to="/about/" activeClassName="active">
                    <Pedal />
                    About us
                </MenuItem>
                <CallToAction href="https://onboarding.testbox.com">
                    Sign up now!
                </CallToAction>
            </NavMenu>
        </Container>
    );
}

export default Header;
