import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    position: absolute;
    top: -225px;
    right: 0;
`;

export default function Sparkle() {
    return (
        <Wrapper>
            <svg
                width="124"
                height="207"
                viewBox="0 0 124 207"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M51 13.4284V44.1082C51 45.6983 51.9418 47.1373 53.3989 47.7738L74.7183 57.0861C77.5087 58.7438 81.4618 57.7768 83.0895 57.0861L103.011 47.5422C104.052 47.0434 104.715 45.9913 104.715 44.8367V12.7293C104.715 11.5596 104.035 10.4965 102.973 10.0059L83.7871 1.14113C80.4386 -0.516497 76.1135 -0.00997686 74.7183 0.450476L53.3989 9.76282C51.9418 10.3993 51 11.8383 51 13.4284Z"
                    fill="#86C3AF"
                />
                <path
                    d="M0 86.5822V101.162C0 102.752 0.941771 104.191 2.39887 104.828L13.086 109.496C14.6255 110.41 16.8065 109.877 17.7045 109.496L27.932 104.596C28.9733 104.097 29.6359 103.045 29.6359 101.891V85.8831C29.6359 84.7134 28.956 83.6504 27.8942 83.1598L18.0894 78.6296C16.242 77.715 13.8557 77.9945 13.086 78.2485L2.39888 82.9167C0.941773 83.5531 0 84.9922 0 86.5822Z"
                    fill="#86C3AF"
                />
                <path
                    d="M83 173.819V195.829C83 197.419 83.9418 198.858 85.3989 199.494L100.993 206.306C103.11 207.563 106.108 206.83 107.343 206.306L122.044 199.263C123.086 198.764 123.748 197.712 123.748 196.557V173.12C123.748 171.95 123.068 170.887 122.007 170.396L107.872 163.866C105.332 162.608 102.051 162.992 100.993 163.342L85.3989 170.153C83.9418 170.79 83 172.229 83 173.819Z"
                    fill="#87C3AF"
                />
            </svg>
        </Wrapper>
    );
}
