const breakpoints = {
    small: "1100px",
    large: "1550px",
};

export const breakpointsV2 = {
    small: "860px",
    medium: "1100px",
    large: "1499px",
};

export default breakpoints;
