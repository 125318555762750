import React from "react";
import styled from "styled-components";

import * as colors from "../styles/colors";
import Ready from "../assets/ready-illustration.png";
import FixedWidth from "./design-system/FixedWidth";
import breakpoints from "./design-system/Breakpoints";
import { fontFamilies } from "../styles/fonts";
import Sparkle from "./Sparkle";

const Background = styled.div`
    background-color: white;
`;

const Block = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: ${breakpoints.small}) {
        padding: 40px;
        flex-direction: column;
        align-items: center;
    }

    @media (min-width: ${breakpoints.small}) {
        margin: 120px 140px 120px 140px;
        flex-direction: row;
    }
`;

const HFImgWrapper = styled.div`
    width: 55%;
`;

const HFImg = styled.img`
    max-width: 100%;
    max-height: 295px;
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const H3 = styled.h3`
    font-family: ${fontFamilies.header};
    color: ${colors.textColors[0]};
    font-weight: 800;
    line-height: 132%;

    @media (min-width: ${breakpoints.small}) {
        font-size: 3rem;
    }
    @media (max-width: ${breakpoints.small}) {
        font-size: 38px;
    }
`;

const Button = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 16px 10px;
    background-color: ${colors.LIGHT_TEAL};
    border-radius: 4px;
    font-family: ${fontFamilies.text};
    text-decoration: none;
    color: ${colors.textColors[0]};
`;

export default function CTABlock() {
    return (
        <Background id="contact">
            <FixedWidth>
                <Sparkle />
                <Block>
                    <HFImgWrapper>
                        <HFImg src={Ready} />
                    </HFImgWrapper>
                    <Content>
                        <H3>Ready for the future of testing software?</H3>
                        <Button href="https://onboarding.testbox.com">
                            Start testing now!
                        </Button>
                    </Content>
                </Block>
            </FixedWidth>
        </Background>
    );
}
