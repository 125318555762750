import React from "react";
import Wrapper from "./Wrapper";
import * as colors from "../../styles/colors";

export default function Expand(props) {
    return (
        <Wrapper {...props}>
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="31"
                    height="31"
                    rx="3.5"
                    fill={"white"}
                    stroke={"#002A2B"}
                />
                <path
                    d="M21.25 14.4375H17.3125V10.5C17.3125 10.0352 16.9023 9.625 16.4375 9.625H15.5625C15.0703 9.625 14.6875 10.0352 14.6875 10.5V14.4375H10.75C10.2578 14.4375 9.875 14.8477 9.875 15.3125V16.1875C9.875 16.6797 10.2578 17.0625 10.75 17.0625H14.6875V21C14.6875 21.4922 15.0703 21.875 15.5625 21.875H16.4375C16.9023 21.875 17.3125 21.4922 17.3125 21V17.0625H21.25C21.7148 17.0625 22.125 16.6797 22.125 16.1875V15.3125C22.125 14.8477 21.7148 14.4375 21.25 14.4375Z"
                    fill={colors.DARK_GREEN}
                />
            </svg>
        </Wrapper>
    );
}
