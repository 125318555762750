export const HEADER = "Inter, Arial, sans-serif";

export const HEADER_SIZE = "52px";
export const HEADER_SIZE_MOBILE = "48px";

export const fontFamilies = {
    text: "Roboto, Arial, sans-serif",
    header: "Inter, Arial, sans-serif",
};

export const HEADER_SIZES = {
    desktop: "40px",
    mobile: "36px",
};
export const headerSizes = HEADER_SIZES;

export const lineSizes = {
    desktop: "66px",
    mobile: "40px",
};

export const textSize = "1.125rem";
