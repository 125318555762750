import styled from "styled-components";
import breakpoints from "./Breakpoints";

export const FixedWidth = styled.div`
    @media (min-width: ${breakpoints.large}) {
        width: ${breakpoints.large};
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }
`;

export default FixedWidth;
