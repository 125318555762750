import React from "react";
import styled from "styled-components";
import HomeIllustration from "../../assets/home-illustration.png";
import breakpoints, { breakpointsV2 } from "../design-system/Breakpoints";

const PedalWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;

    @media (max-width: ${breakpointsV2.small}) {
        display: none;
    }
`;
const MobilePedalWrapper = styled.div`
    @media (min-width: ${breakpointsV2.small}) {
        display: none;
    }
    position: absolute;
    z-index: -1;
`;
const MobileSVG = styled.svg`
    max-width: 55vw;
`;
const DesktopPedal = styled.svg`
    @media (max-width: ${breakpointsV2.medium}) {
        max-width: 45vw;
    }
`;
const MobilePedal = () => {
    return (
        <MobilePedalWrapper>
            <MobileSVG
                width="761"
                height="822"
                viewBox="0 0 761 822"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 168.914V646.499C0 656.038 5.64938 664.672 14.3906 668.491L336.026 809.029C375.558 832.521 431.563 818.817 454.623 809.029L747.372 668.734C755.701 664.743 761 656.327 761 647.091V168.556C761 159.2 755.562 150.696 747.069 146.771L464.506 16.1722C417.068 -7.31982 355.792 -0.141382 336.026 6.38419L14.3906 146.921C5.64939 150.741 0 159.374 0 168.914Z"
                    fill="#24695C"
                />
            </MobileSVG>
        </MobilePedalWrapper>
    );
};
function Pedal() {
    return (
        <>
            <MobilePedal />
            <PedalWrapper>
                <DesktopPedal
                    width="597"
                    height="670"
                    viewBox="0 0 597 670"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0 16.9137V494.499C0 504.038 5.64938 512.672 14.3906 516.491L336.026 657.029C375.558 680.521 431.563 666.817 454.623 657.029L747.372 516.734C755.701 512.743 761 504.327 761 495.091V16.5562C761 7.19962 755.562 -1.30392 747.069 -5.22943L464.506 -135.828C417.068 -159.32 355.792 -152.141 336.026 -145.616L14.3906 -5.0786C5.64939 -1.25919 0 7.37445 0 16.9137Z"
                        fill="#24695C"
                    />
                </DesktopPedal>
            </PedalWrapper>
        </>
    );
}

const IllustrationImg = styled.img`
    max-width: 100%;

    @media (max-width: ${breakpointsV2.small}) {
        width: 50%;
        margin: 20px;
    }
    @media (min-width: ${breakpointsV2.small}) and (max-width: ${breakpointsV2.medium}) {
        width: 85%;
    }
`;
const IllustrationWrapper = styled.div`
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;

    @media (min-width: ${breakpoints.small}) {
        margin-right: 140px;
    }
    @media (max-width: ${breakpoints.small}) {
        margin: 40px 0;
    }
`;

export default function Illustration() {
    return (
        <IllustrationWrapper>
            <Pedal />
            <IllustrationImg src={HomeIllustration} />
        </IllustrationWrapper>
    );
}
